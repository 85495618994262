<template>
  <el-main>
    <div class="searchdiv">
      <div class="searchform">
        <el-input
            size="mini" v-model="searchinfo.account" :placeholder="$t('user.account')" :label="$t('user.account')"
                  clearable></el-input>
        <el-input
            size="mini" v-model="searchinfo.realname" :placeholder="$t('user.realname')" :label="$t('user.realname')"
                  clearable></el-input>
        <el-button
            size="mini" type="primary" @click="dosearch()" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.search') }}
        </el-button>
      </div>
      <div>
        <el-button
            size="mini" type="primary" @click="adduser('userform')" icon="el-icon-plus">{{ $t('global.add') }}
          {{ $t('user.account') }}
        </el-button>
      </div>
    </div>
    <div class="pagecontent">
      <el-table ref="multipleTable"
                border
                size="mini"
                :data="userlist"
                tooltip-effect="dark"
                style="width: 100%">
        <el-table-column
            :label="$t('user.account')"
            width="120" prop="account">
        </el-table-column>
        <el-table-column
            prop="realname"
            :label="$t('user.realname')"
            width="120">
        </el-table-column>
        <el-table-column :label="$t('user.mobile')">
          <template slot-scope="scope">{{ scope.row.mobile }}</template>
        </el-table-column>
        <el-table-column
            :label="$t('user.role')"
            width="120" prop="role_name">
        </el-table-column>
        <el-table-column
            :label="$t('user.last_login_time')"
            width="150" prop="last_login_at">
        </el-table-column>
        <el-table-column
            :label="$t('user.last_login_ip')"
            width="150" prop="last_login_ip">
        </el-table-column>
        <el-table-column
            :label="$t('global.state')"
            width="120" prop="status">
          <template slot-scope="scope">
            <el-link :type="scope.row.status?'success':'danger'"
                     @click="setenable(scope.row)">{{ scope.row.status ? $t('global.enable') : $t('global.disable') }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column :label="$t('global.action')" width="165" fixed="right">
          <template slot-scope="scope">
            <el-link class="optlink" @click="edituser(scope.row)">{{ $t('global.edit') }}</el-link>
            <el-link class="optlink" @click="deluser(scope.row)">{{ $t('global.delete') }}</el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @size-change="SizeChange"
          @current-change="getuserlist"
          :current-page="searchinfo.page"
          :page-sizes="PAGE.pageSizes"
          :page-size="searchinfo.pagesize"
          :layout="PAGE.layout"
          :total="totalRows">
      </el-pagination>
    </div>
    <el-dialog :title="userformdata.uid?$t('user.editaccount'):$t('user.addaccount')" :visible.sync="isshowdialog"
               :close-on-click-modal="false">
      <el-form
          size="mini" :model="userformdata" :rules="rules" ref="userform" :show-message="false">
        <el-form-item :label="$t('user.account')" label-width="120px" prop="account" required>
          <el-input v-model="userformdata.account" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.password')" label-width="120px" prop="password"
                      :required="userformdata.uid?false:true">
          <el-input type="password" v-model="userformdata.password"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.realname')" label-width="120px" prop="realname" required>
          <el-input v-model="userformdata.realname"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.mobile')" label-width="120px" prop="mobile" required>
          <el-input v-model="userformdata.mobile"></el-input>
        </el-form-item>
        <el-form-item :label="$t('global.state')" label-width="120px" required>
          <el-switch v-model="userformdata.status" :active-value="1" :inactive-value="0" :active-text="$t('global.yes')"
                     :inactive-text="$t('global.no')"></el-switch>
        </el-form-item>
        <el-form-item :label="$t('user.enterrole')" label-width="120px" prop="role_id" required>
          <el-select v-model="userformdata.role_id" :placeholder="$t('user.enterrole')">
            <template v-for="dep in deplist">
              <el-option
                  :key="dep.role_id"
                  :label="dep.role_name"
                  :value="dep.role_id">
              </el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('global.remarks')" label-width="120px" prop="remarks">
          <el-input type="textarea" v-model="userformdata.remarks"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isshowdialog = false"
                   size="mini" :disabled="isbusy" :loading="isbusy">{{ $t('global.cancel') }}
        </el-button>
        <el-button type="primary"
                   size="mini" @click="dosubmit('userform')" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.submit') }}
        </el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'user',
  components: {},
  data() {
    return {
      searchinfo: {
        page: 1,
        pagesize: this.PAGE.pageSize,
        account: '',
        realname: ''
      },
      userlist: [],
      multipleSelection: [],
      totalRows: 0,
      defaultuserinfo: {
        uid: 0,
        account: '',
        realname: '',
        password: '',
        role_id: '',
        mobile: '',
        status: 1,
        remarks: '',
      },
      userformdata: {},
      rules: {
        password: [
          {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'}
        ]
      },
      isshowdialog: false,
      deplist: [],
      isbusy: false,
    }
  },
  methods: {
    SizeChange(val) {
      this.searchinfo.pagesize = val;
      this.getuserlist(1);
    },
    async dosearch() {
      this.getuserlist(1);
    },
    async getuserlist(page) {
      this.searchinfo.page = page || this.searchinfo.page;
      this.isbusy = true;
      let res = await this.httpget('/system/user/index', this.searchinfo);
      if (res.code === 0) {
        this.userlist = res.data.data;
        this.totalRows = res.data.total;
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }

    },
    edituser(item) {
      this.userformdata = null;
      item.password = undefined;
      this.userformdata = this.cloneData(item);
      this.isshowdialog = true;
    },
    adduser(formName) {
      this.userformdata = this.cloneData(this.defaultuserinfo);
      this.isshowdialog = true;
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      let res = await this.httppost('/system/user/save', this.userformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getuserlist(1);
        this.userformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async setenable(item) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/system/user/setenable', {uid: item.uid});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          let idx = this.userlist.findIndex((value) => {
            return value.uid === item.uid
          })
          if (idx !== -1) {
            this.userlist[idx].status = Math.abs(this.userlist[idx].status - 1);
          }
          this.isbusy = false;
        } else {
          this.isbusy = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    async deluser(item) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/system/user/delete', {uid: item.uid});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          this.getuserlist();
        } else {
          this.$message({type: 'error', message: res.msg});
        }
        this.isbusy = false;
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    async getdeplist() {
      this.isbusy = true;
      let res = await this.httpget('/system/publicapi/getrole');
      if (res.code === 0) {
        this.deplist = res.data;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    }
  },
  mounted() {
    this.getuserlist(1);
    this.getdeplist();
  },
  computed: {},
}
</script>

<style>
.uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.uploader .el-upload:hover {
  border-color: #409EFF;
}

.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.preview {
  width: 178px;
  height: 50px;
  display: block;
}
</style>
